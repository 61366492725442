import { useQuery } from 'react-query';
import AffordableSetUpService from '../../services/affordableSetUpService';

/**
 * This hook is used to get the RD Monthly Project Payment.
 * -
 * @param {*} organizationId
 * @param {*} propertyId
 * @returns
 */

const useGetRDMonthlyProjectPayment = (organizationId, propertyId, options) => {
  const affordableSetUpService = new AffordableSetUpService();

  return useQuery(
    ['RDMonthlyProjectPayment', organizationId, propertyId],
    () =>
      affordableSetUpService.getRDMonthlyProjectPayment(
        organizationId,
        propertyId,
      ),
    {
      ...options,
      refetchOnWindowFocus: false,
      enabled: !!organizationId && !!propertyId,
    },
  );
};

export default useGetRDMonthlyProjectPayment;
