import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const HEADERS = [
  {
    id: 'position',
    label: <FormattedMessage {...messages.position} />,
    dataType: 'number',
    sortable: true,
  },
  {
    id: 'affordablePrograms',
    label: <FormattedMessage {...messages.affordablePrograms} />,
    dataType: 'string',
    multiselect: true,
  },
  {
    id: 'applicationCode',
    label: <FormattedMessage {...messages.applicationCode} />,
    dataType: 'number',
    searchable: true,
  },
  {
    id: 'applicants',
    label: <FormattedMessage {...messages.applicants} />,
    dataType: 'string',
    searchable: true,
  },
  {
    id: 'texting',
    label: <FormattedMessage {...messages.texting} />,
    dataType: 'number',
    multiselect: true,
    filterOptions: [
      { text: 'New', value: 'new' },
      { text: 'Unresolved', value: 'unresolved' },
    ],
  },
  {
    id: 'householdStatus',
    label: <FormattedMessage {...messages.householdStatus} />,
    dataType: 'string',
    multiselect: true,
  },
  {
    id: 'status',
    label: <FormattedMessage {...messages.status} />,
    dataType: 'string',
    multiselect: true,
  },
  {
    id: 'phoneNumber',
    label: <FormattedMessage {...messages.phoneNumber} />,
    dataType: 'string',
    searchable: true,
  },
  {
    id: 'emailAddress',
    label: <FormattedMessage {...messages.emailAddress} />,
    dataType: 'string',
    searchable: true,
  },
  {
    id: 'desiredMoveInDate',
    label: <FormattedMessage {...messages.desiredMoveInDate} />,
    dataType: 'date',
    searchable: true,
  },
  {
    id: 'applicationReceived',
    label: <FormattedMessage {...messages.applicationReceived} />,
    dataType: 'date',
    searchable: true,
  },
  {
    id: 'applicationRemoved',
    label: <FormattedMessage {...messages.applicationRemoved} />,
    dataType: 'date',
    searchable: true,
  },
  {
    id: 'preferredUnitSize',
    label: <FormattedMessage {...messages.preferredUnitSize} />,
    dataType: 'string',
    multiselect: true,
  },
  {
    id: 'householdSize',
    label: <FormattedMessage {...messages.householdSize} />,
    dataType: 'number',
    searchable: true,
  },
  {
    id: 'incomeLevel',
    label: <FormattedMessage {...messages.incomeLevel} />,
    dataType: 'string',
    multiselect: true,
  },
  {
    id: 'datesContacted',
    label: <FormattedMessage {...messages.datesContacted} />,
    dataType: 'date',
    searchable: true,
  },
  {
    id: 'moveInDate',
    label: <FormattedMessage {...messages.moveInDate} />,
    dataType: 'date',
    searchable: true,
  },
  {
    id: 'requestingAccommodation',
    label: <FormattedMessage {...messages.requestingAccommodation} />,
    dataType: 'string',
    multiselect: true,
  },
  {
    id: 'preferences',
    label: <FormattedMessage {...messages.preferences} />,
    dataType: 'string',
    searchable: false,
  },
  {
    id: 'assignedTo',
    label: <FormattedMessage {...messages.assignedTo} />,
    dataType: 'string',
    searchable: true,
  },
  {
    id: 'notes',
    label: <FormattedMessage {...messages.notes} />,
  },
  {
    id: 'actions',
    label: <FormattedMessage {...messages.actions} />,
    dataType: 'string',
  },
];

export const PROPERTY_PATH_MAP = {
  position: {
    path: 'position',
    dataType: 'number',
  },
  affordablePrograms: {
    path: 'affordablePrograms',
    dataType: 'string',
  },
  applicationCode: {
    path: 'applicationCode',
    dataType: 'number',
  },
  applicants: {
    path: 'applicants',
    dataType: 'string',
  },
  texting: {
    path: 'texting',
    dataType: 'number',
  },
  householdStatus: {
    path: 'householdStatus',
    dataType: 'string',
  },
  status: {
    path: 'applicationStatus.name',
    dataType: 'string',
  },
  phoneNumber: {
    path: 'phoneNumber',
    dataType: 'string',
  },
  emailAddress: {
    path: 'emailAddress',
    dataType: 'string',
  },
  desiredMoveInDate: {
    path: 'desiredMoveInStartDate',
    dataType: 'date',
  },
  applicationReceived: {
    path: 'applicationReceived',
    dataType: 'date',
  },
  applicationRemoved: {
    path: 'applicationRemoved',
    dataType: 'date',
  },
  preferredUnitSize: {
    path: 'preferredUnitSize',
    dataType: 'number',
  },
  householdSize: {
    path: 'householdSize',
    dataType: 'number',
  },
  incomeLevel: {
    path: 'incomeLevel',
    dataType: 'string',
  },
  datesContacted: {
    path: 'datesContacted',
    dataType: 'date',
  },
  moveInDate: {
    path: 'moveInDate',
    dataType: 'date',
  },
  requestingAccommodation: {
    path: 'requestingAccommodation',
    dataType: 'string',
  },
  preferences: {
    path: 'preferences',
    dataType: 'string',
  },
  assignedTo: {
    path: 'assigneeName',
    dataType: 'string',
  },
};
