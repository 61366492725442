import { useMemo, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

// Components
import {
  Modal,
  ModalConfirm,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { SettingsIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

// Hooks
import useReactHookFormProps from '../../../hooks/useReactHookFormProps';
import { useRDMonthlyProjectPaymentMutation } from '../hooks.mincTransactionsMutations';

// Others
import messages from '../messages';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';

/**
 * This component will be used later on for the modals managed in the ManageMincTransactions screen.
 * -
 * @param {*} param0
 * @returns
 */
const ManageMincTransactionsModals = ({
  organizationId,
  propertyId,
  intl,
  modalState,
  modalType,
  open,
  openConfirm,
  openConfirmModal,
  handleClose,
  refetchMonthlyProjectPayment,
  rdProjectData,
}) => {
  const {
    mutate: updateRDMonthlyProjectPayment,
    isLoading: updateRDMonthlyProjectPaymentIsLoading,
  } = useRDMonthlyProjectPaymentMutation({
    organizationId,
    propertyId,
    options: {
      onSuccess: (res) => {
        toastr.success('Success', 'Successfully Updated Project Payment');
        handleClose();
        refetchMonthlyProjectPayment();
      },
      onError: (e) => {
        toastr.error('Error', e);
      },
    },
  });

  const ReactHookFormProps = useReactHookFormProps();
  const { reset, getValues, isValid, isDirty } = ReactHookFormProps;

  useEffect(() => {
    const defaultValues = {
      configureProjectPayment: {
        RDMonthlyProjectPayment: rdProjectData?.RDMonthlyProjectPayment ?? 0,
      },
    }[modalType];

    reset(defaultValues);
  }, [reset, modalType, rdProjectData]);

  const configureProjectPaymentFormFields = useCallback(() => {
    let disabled = false;
    return [
      {
        variant: 'number',
        adornment: '$',
        name: 'RDMonthlyProjectPayment',
        label: <FormattedMessage {...messages.amountDue} />,
        rules: {
          required: true,
          min: 1,
          step: 0.01,
        },
        disabled,
        GridProps: {
          xs: 6,
        },
      },
    ];
  }, []);

  const configureGenerateProjectWorksheetFormFields = useCallback(() => {
    return [
      {
        variant: 'date',
        name: 'projectWorksheetMonth',
        placeholder: 'Select a month',
        rules: {
          required: true,
        },
        GridProps: {
          xs: 6,
        },
        openTo: 'month',
        views: ['year', 'month'],
        maxDate: moment().add(1, 'year'),
      },
    ];
  }, []);

  const { confirmModalProps, modalProps, formProps } = useMemo(() => {
    const props = {
      modalProps: {},
      formProps: {},
      confirmModalProps: {},
    };

    switch (modalType) {
      case 'configureProjectPayment':
        props.modalProps = {
          title: (
            <FormattedMessage {...messages.configureProjectPaymentModalTitle} />
          ),
          icon: <SettingsIcon />,
          actionsProps: [
            {
              children: 'Cancel',
              onClick: handleClose,
            },
            {
              children: 'Save',
              submitButton: true,
              isSubmitting: updateRDMonthlyProjectPaymentIsLoading,
              disabled: !isValid || !isDirty,
              onClick: () => {
                const values = getValues();
                const payload = {
                  RDMonthlyProjectPayment:
                    values?.RDMonthlyProjectPayment ?? null,
                };
                updateRDMonthlyProjectPayment({ payload });
              },
            },
          ],
          ModalProps: {
            PaperProps: {
              sx: { width: { xs: 'calc(100% - 32px)', sm: 500 } },
            },
          },
        };
        props.formProps = {
          header: (
            <Typography variant={'bodyMedium'} color={'text.secondary'}>
              <FormattedMessage {...messages.monthlyMortgageFormHeader} />
            </Typography>
          ),
          fields: configureProjectPaymentFormFields({ intl }),
        };
        break;
      case 'generateProjectWorksheet':
        props.modalProps = {
          title: (
            <FormattedMessage
              {...messages.generateProjectWorksheetModalTitle}
            />
          ),
          actionsProps: [
            {
              children: 'Cancel',
              onClick: handleClose,
            },
            {
              children: 'Generate',
              disabled: !isValid || !isDirty,
              submitButton: true,
              onClick: () => {
                // TODO: Implement report in next card
              },
            },
          ],
          ModalProps: {
            PaperProps: {
              sx: { width: { xs: 'calc(100% - 32px)', sm: 500 } },
            },
          },
        };
        props.formProps = {
          fields: configureGenerateProjectWorksheetFormFields(),
        };
        break;
      default:
        break;
    }

    return props;
  }, [
    // mutations
    updateRDMonthlyProjectPayment,
    updateRDMonthlyProjectPaymentIsLoading,
    // form
    getValues,
    isDirty,
    isValid,
    // modal
    handleClose,
    modalType,
    intl,
    // confirm modal form
    configureProjectPaymentFormFields,
    configureGenerateProjectWorksheetFormFields,
  ]);

  return (
    <>
      <Modal open={open} onClose={handleClose} {...modalProps}>
        <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
      </Modal>
      <ModalConfirm open={openConfirm} {...confirmModalProps} />
    </>
  );
};

export default ManageMincTransactionsModals;
