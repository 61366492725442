import { get, put, post } from '../utils/api';

export default class AffordableSetUpService {
  getPropertyIncomeAveragingPercent(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/income-averaging-percent`,
      options,
    );
  }

  modifyPropertyIncomeAveragingPercent(
    organizationId: string,
    propertyId: string,
    incomeAveragingPercent: string,
  ) {
    return put(
      `/${organizationId}/${propertyId}/income-averaging-percent`,
      JSON.stringify({ incomeAveragingPercent }),
    );
  }

  async getAllPassbookRatesByState(
    organizationId: string,
    propertyId: string,
    state: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/passbook-rates/${state}`,
      options,
    );
  }

  async getRDMonthlyProjectPayment(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/rd-monthly-project-payment`,
      options,
    );
  }

  async updateRDMonthlyProjectPayment(
    organizationId: string,
    propertyId: string,
    payload: Object,
    options?: Object,
  ) {
    return post(
      `/${organizationId}/${propertyId}/rd-monthly-project-payment`,
      JSON.stringify(payload),
      options,
    );
  }
}
