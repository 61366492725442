import {
  Stack,
  Tooltip,
  Typography,
  Box,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import type { Props } from './types';
import messages from '../messages';

function ScreeningDetailsTooltip({ intl, missingRequiredFields }: Props) {
  if (!missingRequiredFields.length) return null;
  return (
    <Tooltip
      placement="top"
      variant="light"
      arrow={false}
      title={
        <Stack spacing={1} padding={2} paddingRight={6}>
          <Typography variant="titleSmall">
            {intl.formatMessage(messages.missingRequiredFields)}
          </Typography>
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {missingRequiredFields.map((field) => (
              <Typography component="li" variant="bodySmall">
                {field}
              </Typography>
            ))}
          </Box>
        </Stack>
      }
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        paddingX={0.5}
        paddingY={1}
        sx={{
          '&:hover': {
            backgroundColor: 'action.hover',
            borderRadius: '4px',
          },
        }}
      >
        <AlertInfoIcon fontSize="xSmall" />
        <Typography
          variant="labelSmall"
          color="text.secondary"
          sx={{
            marginTop: '1px !important',
          }}
        >
          {intl.formatMessage(messages.details)}
        </Typography>
      </Stack>
    </Tooltip>
  );
}

export default ScreeningDetailsTooltip;
