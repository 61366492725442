import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';

// Components
import ManageMincTransactionsModals from './ManageMincTransactionsModals';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import GenerateReportButton from './GenerateReportButton';
import GenerateProjectWorksheetButton from './GenerateProjectWorksheetButton';
import TableLayout from '../../components/TableLayout';

// Hooks
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useManageMincTransactions from './hooks';
import useBaseTableProps from '../../hooks/useBaseTableProps';
import useHasPermission from '../../hooks/useHasPermission';
import useGetRDMonthlyProjectPayment from './hooks.useGetRDMonthlyProjectPayment';

// Others
import { MincTransaction } from './types';
import messages from './messages';
import ConfigureProjectPaymentButton from './ConfigureProjectPaymentButton';

type Props = {
  organizationId: string,
  propertyId: string,
  intl: Object,
};

/**
 * The central component for the Manage MINC Transactions page.
 */
const ManageMincTransactions = ({ intl }: Props) => {
  const { id: propertyId, organizationId } = useSelectedProperty();
  const baseTableProps = useBaseTableProps();

  const {
    modalProps,
    // Handle Sort/Filter/Search
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    // Columns
    handleColumnChange,
    allColumnsHidden,
    columnOptions,
    selectedColumns,
    // Indicators
    isLoading,
    count,
    totalCount,
    name,
    order,
    orderBy,
    // Data
    filteredHeaders,
    rows,
    onCSVButtonClick,
    generateReport,
    selectedIds,
    isReportLoading,
    // Pagination
    // pageCount,
    // perPage,
    // currentPage,
    // onPageChange,
    // Checkboxes
    isAtLeastOneRowSelected,
  } = useManageMincTransactions({
    organizationId,
    propertyId,
  });

  const mincTransactions: Array<MincTransaction> = rows;

  const hasConfigureProjectPaymentPermission = useHasPermission(
    'rd-project-payment-configure',
  );

  const { data: rdProjectData, refetch: refetchMonthlyProjectPayment } =
    useGetRDMonthlyProjectPayment(organizationId, propertyId);
  const hasMonthlyMortgagePayment = rdProjectData?.RDMonthlyProjectPayment > 0;
  return (
    <DocumentTitle
      data-testid={'manage-minc-transactions'}
      title={intl.formatMessage(messages.title)}
    >
      <>
        <TableLayout name={name} childrenElementsHeight={113 + 158}>
          {(tableHeight) => (
            <>
              <TableHeader
                title={<FormattedMessage {...messages.title} />}
                count={count}
                totalCount={totalCount}
                actions={
                  <>
                    <ConfigureProjectPaymentButton
                      handleOpenModal={modalProps.handleOpen}
                      disabled={!hasConfigureProjectPaymentPermission}
                    />
                    <GenerateProjectWorksheetButton
                      handleOpenModal={modalProps.handleOpen}
                      intl={intl}
                      disabled={!hasMonthlyMortgagePayment}
                      showToolTip={!hasMonthlyMortgagePayment}
                    />
                    <GenerateReportButton
                      organizationId={organizationId}
                      propertyId={propertyId}
                      selectedIds={Array.from(selectedIds)}
                      intl={intl}
                      disabled={
                        isLoading || !isAtLeastOneRowSelected || isReportLoading
                      }
                      showToolTip={!isAtLeastOneRowSelected}
                      onClick={generateReport}
                    />
                  </>
                }
              />
              <Table
                {...baseTableProps}
                // Handle Sort/Filter/Search
                handleSortChange={handleSortChange}
                handleFilterChange={handleFilterChange}
                handleFilterTypeChange={handleFilterTypeChange}
                handleSearchSubmit={handleSearchSubmit}
                handleDateSubmit={handleDateSubmit}
                filterState={filterState}
                filterTypeState={filterTypeState}
                searchState={searchState}
                dateState={dateState}
                // Columns
                handleColumnChange={handleColumnChange}
                allColumnsHidden={allColumnsHidden}
                columnOptions={columnOptions}
                selectedColumns={selectedColumns}
                // Indicators
                isLoading={isLoading}
                count={count}
                totalCount={totalCount}
                name={name}
                order={order}
                orderBy={orderBy}
                // Data
                headers={filteredHeaders}
                rows={mincTransactions}
                onCSVButtonClick={count > 0 ? onCSVButtonClick : undefined}
                // Pagination
                // pageCount={pageCount}
                // perPage={perPage}
                // currentPage={currentPage}
                // onPageChange={(event, page) => {
                //   onPageChange(page);
                // }}
                // Others
                height={tableHeight}
                intl={intl}
              />
              <ManageMincTransactionsModals
                organizationId={organizationId}
                propertyId={propertyId}
                refetchMonthlyProjectPayment={refetchMonthlyProjectPayment}
                rdProjectData={rdProjectData}
                intl={intl}
                {...modalProps}
              />
            </>
          )}
        </TableLayout>
      </>
    </DocumentTitle>
  );
};

export default injectIntl(ManageMincTransactions);
