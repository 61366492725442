import React from 'react';
import { Button } from 'react-bootstrap';
import Spinner from '../Spinner';
import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';

type Props = {
  bsStyle?: string,
  classes?: string,
  clickHandler: Function,
  isSubmitting?: boolean,
  children: any,
  hidden?: boolean,
  disabled?: boolean,
};

const SubmitButton = ({
  bsStyle,
  classes,
  clickHandler,
  isSubmitting,
  children,
  hidden,
  disabled,
  tooltip,
}: Props) => {
  if (hidden) return null;
  const styles = {};
  if (bsStyle) {
    styles.bsStyle = bsStyle;
  }
  if (classes) {
    styles.className = classes;
  }
  const ButtonContainer = () => {
    return (
      <Button
        disabled={disabled || isSubmitting}
        onClick={() => clickHandler()}
        {...styles}
      >
        {isSubmitting ? <Spinner small /> : children}
      </Button>
    );
  };

  return tooltip ? (
    <Tooltip title={tooltip}>
      <ButtonContainer />
    </Tooltip>
  ) : (
    <ButtonContainer />
  );
};

export default SubmitButton;
