import { useEffect, useMemo } from 'react';
import useReactHookFormProps from '../../../hooks/useReactHookFormProps';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

const useApplicationStatusSingleSelect = ({
  handleApplicationStatusChange,
  statusUpdateAllowed,
  disableStatusChange,
  disableWaitlistOption,
  addToWaitlistParams,
  applicationStatusOptions,
  applicationStatusId,
  currentStatusId,
}) => {
  const ReactHookFormProps = useReactHookFormProps({});
  const { reset, setValue } = ReactHookFormProps;

  useEffect(() => {
    const defaultValues = {
      applicationStatusId: applicationStatusId,
    };

    reset(defaultValues);
  }, [reset, applicationStatusId]);

  const { formProps } = useMemo(() => {
    const props = {
      formProps: {},
    };

    props.formProps = {
      fields: [
        {
          variant: 'singleSelect',
          name: 'applicationStatusId',
          label: 'Status',
          options: applicationStatusOptions,
          onChange: statusUpdateAllowed
            ? (newStatusId) =>
                handleApplicationStatusChange(
                  null,
                  newStatusId,
                  addToWaitlistParams,
                )
            : () => null,
          InputLabelProps: {
            ...(!statusUpdateAllowed || disableWaitlistOption
              ? {
                  TooltipProps: {
                    title: 'Permissions required',
                    children: <AlertInfoIcon color={'blue'} />,
                    arrow: true,
                    placement: 'right',
                  },
                }
              : []),
          },
          HelperTextProps: {
            sx: {
              display: 'none',
            },
          },
          disabled: disableStatusChange,
        },
      ],
    };

    return props;
  }, [
    // Application Status
    addToWaitlistParams,
    applicationStatusOptions,
    disableStatusChange,
    handleApplicationStatusChange,
    disableWaitlistOption,
    statusUpdateAllowed,
    // neccessary to avoid issues when updating from statusA to statusB and viceversa
    currentStatusId,
  ]);

  return {
    ReactHookFormProps,
    formProps,
    setValue,
  };
};

export default useApplicationStatusSingleSelect;
