import { useQuery, QueryOptions } from 'react-query';

import AffordableWaitlistService from '../../services/affordableWaitlistService';

const useManageAffordableWaitlistQuery = ({
  organizationId,
  propertyId,
  options = {},
  filter,
}: {
  organizationId: string,
  propertyId: string,
  householdId: string,
  options?: QueryOptions,
}) => {
  const affordableWaitlistService = new AffordableWaitlistService();
  const queryKey = [
    'getAllAffordableWaitlistsByProperty',
    organizationId,
    propertyId,
    filter?.includeAll,
  ];

  const { data, isLoading, refetch } = useQuery(
    queryKey,
    async () => {
      const response =
        await affordableWaitlistService.getAllAffordableWaitlistsByPropertyId(
          organizationId,
          propertyId,
          filter,
        );
      return response;
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(organizationId?.length && propertyId?.length),
      ...options,
    },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useManageAffordableWaitlistQuery;
