import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Screening.title',
    defaultMessage: 'Screening',
  },
  submitScreening: {
    id: 'App.Screening.submitScreening',
    defaultMessage: 'Submit Screening',
  },
  finalScreeningStatus: {
    id: 'App.Screening.finalScreeningStatus',
    defaultMessage: 'Final Screening Status',
  },
  reasonForDecision: {
    id: 'App.Screening.reasonForDecision',
    defaultMessage: 'Reason for Decision',
  },
  credit: {
    id: 'App.Screening.credit',
    defaultMessage: 'Credit',
  },
  criminal: {
    id: 'App.Screening.criminal',
    defaultMessage: 'Criminal',
  },
  evictions: {
    id: 'App.Screening.evictions',
    defaultMessage: 'Evictions',
  },
  missingRequiredFields: {
    id: 'App.Screening.missingRequiredFields',
    defaultMessage: 'Missing Required Fields',
  },
  notReady: {
    id: 'App.Screening.notReady',
    defaultMessage: 'Not ready',
  },
  notStarted: {
    id: 'App.Screening.notStarted',
    defaultMessage: 'Not started',
  },
  details: {
    id: 'App.Screening.details',
    defaultMessage: 'Details',
  },
});

export default messages;
