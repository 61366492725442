// Table Hooks
import useUniqueTableName from '../../hooks/useUniqueTableName';
import useManageAffordableWaitlistQuery from './hooks.useManageAffordableWaitlistQuery';
import useRows from './hooks.useRows';
import {
  useTableFilterSortSearchManager,
  useTableFilterSortData,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useHeaders from './hooks.useHeaders';

// Other Hooks
import useModalProps from '../../hooks/useModalProps';
import useAffordableWaitlistCSVExport from './hooks.useAffordableWaitlistCSVExport';
import useParseResults from './hooks.useParseResults';

// Constants
import { PROPERTY_PATH_MAP } from './constants';
import useManageAffordableWaitlistPropertyPreferences from './hooks.useManageAffordableWaitlistPropertyPreferences';

const useManageAffordableWaitlist = ({
  organizationId,
  propertyId,
  filter,
}) => {
  const name = useUniqueTableName('manage-affordable-waitlist-table');

  const {
    data,
    isLoading,
    refetch: refetchAffordableWaitlists,
  } = useManageAffordableWaitlistQuery({
    organizationId,
    propertyId,
    filter,
  });

  // Parse results
  const { parsedResults } = useParseResults({ results: data ?? [] });

  const headers = useHeaders({
    results: parsedResults,
  });

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
    initialOrderBy: 'position',
    initialOrder: 'ASC',
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results: parsedResults,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  const modalProps = useModalProps();

  const rows = useRows({
    affordableWaitlistItems: sortedAndFilteredResults,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const { onCSVButtonClick } = useAffordableWaitlistCSVExport({
    filteredHeaders,
    rows,
    hasAnyFilters: Boolean(Object.keys(filterState)?.length),
  });

  // Configure Preferences
  const {
    data: affordableWaitlistPropertyPreferencesData,
    refetch: refetchAffordableWaitlistPropertyPreferences,
  } = useManageAffordableWaitlistPropertyPreferences({
    organizationId,
    propertyId,
  });

  const defaultValuesConfigurePreferences =
    affordableWaitlistPropertyPreferencesData?.preferences?.reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {},
    );

  return {
    modalProps,
    // Handle Sort/Filter/Search
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    // Columns
    handleColumnChange,
    allColumnsHidden,
    columnOptions,
    selectedColumns,
    // Indicators
    isLoading,
    count: rows.length ?? 0,
    totalCount: sortedAndFilteredResults.length ?? 0,
    name,
    order,
    orderBy,
    // Data
    filteredHeaders,
    refetchAffordableWaitlists,
    rows,
    onCSVButtonClick,
    affordableWaitlistPropertyPreferencesData,
    refetchAffordableWaitlistPropertyPreferences,
    defaultValuesConfigurePreferences: {
      id: affordableWaitlistPropertyPreferencesData?.id,
      options: defaultValuesConfigurePreferences,
    },
  };
};

export default useManageAffordableWaitlist;
