import { useMemo } from 'react';
import { useMutation } from 'react-query';
import AffordableSetUpService from '../../services/affordableSetUpService';
import ReportService from '../../services/reportService';

/**
 * Mutations will likely be used later on for the modals managed in the ManageMincTransactions screen
 * -
 * @param {*} param0
 * @returns
 */
export const useRDMonthlyProjectPaymentMutation = ({
  organizationId,
  propertyId,
  options,
}) => {
  const affordableSetUpService = useMemo(
    () => new AffordableSetUpService(),
    [],
  );
  const mutation = useMutation(
    ({ payload }) =>
      affordableSetUpService.updateRDMonthlyProjectPayment(
        organizationId,
        propertyId,
        payload,
      ),
    options,
  );

  return mutation;
};

/**
 * Will probably be used later when generating the MINC Tenant Transactions Report
 * from the ManageMincTransactions screen
 *
 * @param {*} param0
 * @returns
 */
export const useDownloadMincTransactionsReportMutation = ({
  organizationId,
  propertyId,
  startDate,
  endDate,
  options,
}) => {
  const reportService = useMemo(() => new ReportService(), []);
  const mutation = useMutation(
    () =>
      reportService.downloadMINCTenantTransactionsReport(
        organizationId,
        propertyId,
        startDate,
        endDate,
      ),
    options,
  );

  return {
    ...mutation,
  };
};
