import { defineMessages } from 'react-intl';

/**
 * Will have the messages displayed in ManageMincTransactions component
 */
const messages = defineMessages({
  generateProjectWorksheet: {
    id: 'App.ManageMincTransactions.GenerateProjectWorksheetButton.GenerateProjectWorksheet',
    defaultMessage: 'Generate Project Worksheet',
  },
  mustSaveMonthlyMortgagePayment: {
    id: 'App.ManageMincTransactions.GenerateProjectWorksheetButton.MustSaveMonthlyMortgagePayment',
    defaultMessage:
      'A monthly mortgage payment must be saved in order to enable this button.',
  },
});

export default messages;
