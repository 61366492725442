import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { SettingsIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import messages from '../messages';

const ConfigureProjectPaymentButton = ({ disabled, handleOpenModal }) => {
  return (
    <Button
      variant={'primarySubtle'}
      disabled={disabled}
      onClick={() => {
        handleOpenModal({
          modalType: 'configureProjectPayment',
        });
      }}
      startIcon={<SettingsIcon />}
    >
      <FormattedMessage {...messages.configureProjectPaymentButton} />
    </Button>
  );
};

export default ConfigureProjectPaymentButton;
