import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from './messages';

const GenerateProjectWorksheetButton = ({
  disabled,
  showToolTip,
  intl,
  handleOpenModal,
}) => {
  const button = (
    <Button
      variant={'primarySubtle'}
      disabled={disabled}
      intl={intl}
      onClick={() => {
        handleOpenModal({
          modalType: 'generateProjectWorksheet',
        });
      }}
    >
      <FormattedMessage {...messages.generateProjectWorksheet} />
    </Button>
  );
  return (
    <>
      {showToolTip ? (
        <Tooltip
          id={'generateRDProjectWorksheetTooltip'}
          title={
            <FormattedMessage {...messages.mustSaveMonthlyMortgagePayment} />
          }
        >
          {button}
        </Tooltip>
      ) : (
        button
      )}
    </>
  );
};

export default GenerateProjectWorksheetButton;
