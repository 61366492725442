import {
  Box,
  Stack,
  Typography,
  Divider,
  IconBadgeStatus,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from '../messages';
import type { Props } from './types';
import ScreeningDetailsTooltip from '../ScreeningDetailsTooltip';

function TransUnionScreening({ intl, missingRequiredFields }: Props) {
  const badgeVariant = missingRequiredFields.length ? 'notReady' : 'notStarted';
  return (
    <Box
      sx={{
        borderRadius: '4px',
        border: '1px solid',
        borderColor: 'divider',
        padding: 3,
      }}
    >
      <Stack direction="row" gap={2} alignItems="center">
        <Stack width={1 / 3}>
          <IconBadgeStatus variant={badgeVariant} />
          <Typography
            variant="titleMedium"
            color={badgeVariant === 'notReady' ? undefined : 'text.disabled'}
            marginLeft={0.5}
            marginTop={1}
          >
            {intl.formatMessage(messages.credit)}
          </Typography>
          <ScreeningDetailsTooltip
            intl={intl}
            missingRequiredFields={missingRequiredFields}
          />
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack width={1 / 3}>
          <IconBadgeStatus variant={badgeVariant} />
          <Typography
            variant="titleMedium"
            color={badgeVariant === 'notReady' ? undefined : 'text.disabled'}
            marginLeft={0.5}
            marginTop={1}
          >
            {intl.formatMessage(messages.criminal)}
          </Typography>
          <ScreeningDetailsTooltip
            intl={intl}
            missingRequiredFields={missingRequiredFields}
          />
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack width={1 / 3}>
          <IconBadgeStatus variant={badgeVariant} />
          <Typography
            variant="titleMedium"
            color={badgeVariant === 'notReady' ? undefined : 'text.disabled'}
            marginLeft={0.5}
            marginTop={1}
          >
            {intl.formatMessage(messages.evictions)}
          </Typography>
          <ScreeningDetailsTooltip
            intl={intl}
            missingRequiredFields={missingRequiredFields}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default TransUnionScreening;
