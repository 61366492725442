import { get, patch } from '../utils/api';
import { WaitlistInfo } from '../components/WaitlistInfoSection/types';

export default class WaitlistService {
  constructor(organizationId: string, propertyId: string) {
    this.organizationId = organizationId;
    this.propertyId = propertyId;
  }

  getEndpoint(applicationId: string): string {
    return `/${this.organizationId}/${this.propertyId}/application/${applicationId}/waitlist`;
  }

  async getWaitlistById(applicationId: string): Promise<WaitlistInfo> {
    const endpoint = this.getEndpoint(applicationId);
    return get(endpoint);
  }

  /**
   * This update is also called from AddToWaitlistModal, from the BE side of things
   * @param {*} applicationId
   * @param {*} newWaitlistInfo
   * @returns
   */
  async updateWaitlist(
    applicationId: string,
    newWaitlistInfo: WaitlistInfo,
  ): Promise<void> {
    const endpoint = this.getEndpoint(applicationId);
    return patch(endpoint, JSON.stringify(newWaitlistInfo));
  }
}
