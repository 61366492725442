import React from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  IconBadgeStatus,
  Accordion,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { injectIntl } from 'react-intl';
import messages from './messages';
import type { Props } from './types';
import TransUnionScreening from './TransUnionScreening';

function CreditCriminalStatusScreening({
  intl,
  isTransUnionActive,
  screening,
}: Props) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = () => setExpanded(!expanded);

  const missingRequiredFields = React.useMemo(() => {
    if (screening?.error !== 1) return [];
    return screening?.message?.split(',').map((field) => field.trim());
  }, [screening?.message, screening?.error]);
  const badgeVariant = missingRequiredFields.length ? 'notReady' : 'notStarted';

  return (
    <Accordion
      summary={
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          width="100%"
          paddingRight={2}
        >
          <IconBadgeStatus variant={badgeVariant} />
          <Typography variant="bodyLarge">
            {intl.formatMessage(messages.title)}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="primarySubtle"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
            }}
            disabled={missingRequiredFields.length > 0}
          >
            {intl.formatMessage(messages.submitScreening)}
          </Button>
        </Stack>
      }
      expanded={expanded}
      onChange={handleChange}
      sx={{
        marginBottom: 6,
      }}
    >
      <Stack spacing={2}>
        {isTransUnionActive && (
          <TransUnionScreening
            intl={intl}
            missingRequiredFields={missingRequiredFields}
          />
        )}
        <Box
          sx={{
            borderRadius: '4px',
            border: '1px solid',
            borderColor: 'divider',
            backgroundColor: 'background.alt',
            padding: 3,
          }}
        >
          <Stack direction="row">
            <Stack marginRight={16}>
              <Typography variant="labelLarge">
                {intl.formatMessage(messages.finalScreeningStatus)}
              </Typography>
              <Typography variant="bodyMedium">---</Typography>
            </Stack>
            <Stack>
              <Typography variant="labelLarge">
                {intl.formatMessage(messages.reasonForDecision)}
              </Typography>
              <Typography variant="bodyMedium">---</Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Accordion>
  );
}

export default injectIntl(CreditCriminalStatusScreening);
