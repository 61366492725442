import { useFlags } from 'launchdarkly-react-client-sdk';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useHasPermission from '../../hooks/useHasPermission';

/**
 * Custom hook that returns info to decide if the user can perform affordable waitlist
 * actions or not
 * @returns
 */
const useAffordableWaitlistValidations = () => {
  const { affordableWaitlist: affordableWaitlistFlag } = useFlags();

  const { pap, propertyClass, setup } = useSelectedProperty();
  const hasAffordableWaitlistPermissionAdd = useHasPermission(
    'affordable-waitlist-add-remove-select',
  );

  const isPropertyMixedOrAffordable = ['Affordable', 'Mixed'].includes(
    propertyClass?.name,
  );
  const isPropertyLayeredWithHUD = pap?.some(
    (map) => map.masterAffordableProgram?.name === 'HUD',
  );
  const accountThisPropertyForWaitlist =
    isPropertyMixedOrAffordable && isPropertyLayeredWithHUD;

  const affordableWaitlistPropertySetting = setup?.affordableWaitlist;

  const propertyAffordablePrograms = pap?.map(
    (map) => map.masterAffordableProgram?.name,
  );

  return {
    affordableWaitlistFlag,
    hasAffordableWaitlistPermissionAdd,
    accountThisPropertyForWaitlist,
    affordableWaitlistPropertySetting,
    propertyAffordablePrograms,
  };
};

export default useAffordableWaitlistValidations;
