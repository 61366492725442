import { useMemo } from 'react';
import useHasPermission from '../../hooks/useHasPermission';
import { FormattedMessage } from 'react-intl';
import { TextingIndicator } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import { DisabledOptionReason } from './types';

import messages from './messages';

const appStatuses = {
  denied: 'Denied',
  canceled: 'Canceled',
  inProcess: 'In Process',
  convertedToResident: 'Converted to Resident',
  approved: 'Approved',
};

const disabledReasons = {
  permissions: 'Permission required',
  other: 'Action not available',
};

const chooseReason = (option: DisabledOptionReason) => {
  return disabledReasons[option];
};

const useRows = ({ affordableWaitlistItems }) => {
  const hasAddRemoveSelectPermission = useHasPermission(
    'affordable-waitlist-add-remove-select',
  );
  const hasReinstatePermission = useHasPermission(
    'affordable-waitlist-reinstate',
  );

  return useMemo(() => {
    return affordableWaitlistItems.map((row) => {
      const position = row.position;
      const affordablePrograms = row.affordablePrograms;
      const applicationCode = row.applicationCode;
      const applicants = row.applicants;
      const texting = row.texting;
      let status = row.applicationStatus?.name;
      if (status === appStatuses.convertedToResident) {
        status = appStatuses.approved;
      }
      const householdStatus = row?.householdStatus;
      const phoneNumber = row.phoneNumber;
      const email = row.emailAddress;
      const desiredMoveInDate = row.desiredMoveInStartDate;
      const applicationReceived = row.applicationReceived;
      const applicationRemoved = row.applicationRemoved;
      const preferredUnitSize = row.preferredUnitSize;
      const householdSize = row.householdSize;
      const incomeLevel = row.incomeLevel;
      const datesContacted = row.datesContacted;
      const moveInDate = row.moveInDate;
      const requestingAccommodation = row.requestingAccommodation;
      const preferences = row.preferences;
      const assignedTo = row.assigneeName;
      const notes = row.notes;

      const hideSelectFromWaitlist =
        Object.values(appStatuses).includes(status);
      const hideCancel = status === appStatuses.canceled;
      const hideDeny = status === appStatuses.denied;
      const hideReinstate = ![
        appStatuses.denied,
        appStatuses.canceled,
      ].includes(status);
      const disableOptions = !hasAddRemoveSelectPermission;
      const tooltipMsg = disableOptions
        ? chooseReason('permissions')
        : undefined;

      const formatPreferences = (preferences) =>
        preferences
          ? Object.entries(preferences)
              .filter((obj) => obj[1])
              .map((obj) => obj[0])
              .join(', ')
          : '';

      return {
        id: row.id,
        position: {
          variant: 'number',
          value: position,
        },
        affordablePrograms: {
          value: affordablePrograms,
        },
        applicationCode: {
          variant: 'number',
          value: applicationCode,
        },
        applicants: {
          variant: 'link',
          value: applicants,
          to: getUrlWithSelectedPropertyId(`/application/${row.applicationId}`),
          target: '_blank',
        },
        texting: {
          variant: texting?.TOTAL > 0 ? 'link' : undefined,
          value: <TextingIndicator {...texting} />,
          to:
            texting?.TOTAL > 0
              ? getUrlWithSelectedPropertyId(
                  `/application/${row.applicationId}?tab=texting`,
                )
              : undefined,
        },
        status: {
          value: status,
        },
        householdStatus: {
          value: householdStatus,
        },
        phoneNumber: {
          variant: 'phoneNumber',
          value: phoneNumber,
        },
        emailAddress: {
          variant: 'email',
          value: email,
        },
        desiredMoveInDate: {
          variant: 'date',
          value: desiredMoveInDate,
        },
        applicationReceived: {
          variant: 'date',
          value: applicationReceived,
        },
        applicationRemoved: {
          variant: 'date',
          value: applicationRemoved,
        },
        preferredUnitSize: {
          variant: 'number',
          value: preferredUnitSize,
        },
        householdSize: {
          variant: 'number',
          value: householdSize,
        },
        incomeLevel: {
          value: incomeLevel,
        },
        datesContacted: {
          variant: 'date',
          value: datesContacted,
        },
        moveInDate: {
          variant: 'date',
          value: moveInDate,
        },
        requestingAccommodation: {
          value: requestingAccommodation ? 'Yes' : 'No',
        },
        preferences: {
          value: formatPreferences(preferences),
        },
        assignedTo: {
          value: assignedTo,
        },
        notes: {
          // a modal will show up with the notes when implemented
          value: notes,
        },
        actions: {
          variant: 'menu',
          name: 'actionsMenu',
          iconName: 'BoltIcon',
          color: 'primary',
          closeOnClick: true,
          options: [
            ...(hideSelectFromWaitlist
              ? []
              : [
                  {
                    disabled: disableOptions,
                    value: 'selectFromWaitlist',
                    text: <FormattedMessage {...messages.selectFromWaitlist} />,
                    tooltip: tooltipMsg,
                    onClick: ({ value }) => {
                      // TODO: Open modal and implement Select from Waitlist action
                    },
                  },
                ]),
            ...(hideCancel
              ? []
              : [
                  {
                    disabled: disableOptions,
                    value: 'cancel',
                    text: <FormattedMessage {...messages.cancel} />,
                    tooltip: tooltipMsg,
                    onClick: ({ value }) => {
                      // TODO: Open modal and implement Cancel action
                    },
                  },
                ]),
            ...(hideDeny
              ? []
              : [
                  {
                    disabled: disableOptions,
                    value: 'deny',
                    text: <FormattedMessage {...messages.deny} />,
                    tooltip: tooltipMsg,
                    onClick: ({ value }) => {
                      // TODO: Open modal and implement Deny action
                    },
                  },
                ]),
            ...(hideReinstate
              ? []
              : [
                  {
                    disabled: !hasReinstatePermission,
                    value: 'reinstate',
                    text: <FormattedMessage {...messages.reinstate} />,
                    tooltip: !hasReinstatePermission
                      ? chooseReason('permissions')
                      : undefined,
                    onClick: ({ value }) => {
                      // TODO: Open modal and implement Reinstate action
                    },
                  },
                ]),
            {
              disabled: false,
              value: 'addNote',
              text: <FormattedMessage {...messages.addNote} />,
              onClick: ({ value }) => {
                // TODO: Open modal and implement Add Note action
              },
            },
          ],
        },
      };
    });
  }, [affordableWaitlistItems]);
};

export default useRows;
